/* Sidebar */

.sidebar {
  //RW code start
  overflow-y: auto;
  position: fixed;
  left: auto;
  height: 100%;
  //RW code End
  min-height: calc(100vh - #{$navbar-height});
  background: $sidebar-gradient;
  font-family: $type1;
  padding: 0;
 //width: $sidebar-width-lg;
 width: 21%; // 27.5%; 
  z-index: 11;
  transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  -webkit-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  -moz-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  -ms-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  .sidebar-brand-wrapper {
    width: auto;
    height: $navbar-height;
     /* Commented code, for meeting the designer expectation*/
    /* End */
    .sidebar-brand {
      // padding: 2.25rem 0 0.25rem 40px;
      text-align: center;
      display: inline-block;
      margin: 0 auto;
      text-decoration: none;
      
      .rtl & {
        padding: 20px 10px 10px 0;
      }

      img {
        //width: calc(#{$sidebar-width-lg} - 80px);
        width: 100%;
        max-width: 70%;
        //height: 48px;
        height: 100%;
        margin: auto;
        vertical-align: middle;
      }
    }

    .brand-logo-mini {
      display: none;
      img {
        //width: calc(#{$sidebar-width-icon} - 50px);
        width: calc(#{$sidebar-width-icon} - 25px);
        max-width: 100%;
        height: 35px;
        margin: auto;
      }
    }
  }
  >.nav {
    overflow: hidden;
    flex-wrap: nowrap;
    flex-direction: column;

    .nav-item {
      .collapse {
        z-index: 999;
      }

      .sub-menu {
        margin: 5px 0;
        flex-wrap: nowrap;

        .rtl & {
          padding: $rtl-sidebar-submenu-padding;
        }

        .nav-item {
          display: block;
          max-width: 100%;
          .nav-link {
            padding: 5px 0px 10px 70px; 
            position: relative;
            color: #2d2d2d;// rgba($sidebar-light-menu-color, 0.8); /* Commented code, for meeting the designer expectation*/

            font-size: calc(#{$sidebar-menu-font-size} - 2px);
            font-weight: 500;
            transition-duration: 0.5s;
            //transition-timing-function: "ease-in-out";
            //transition-property: "background", "color", "box-shadow", "padding";
            display: block;
            max-width: 100%;
            display: block;

            &:before {
              content: "";
              position: absolute;
              top: 0px;
              left: 35px;
              display: block;
              height: 100%;
              width: 2px;
              background: $sidebar-light-submenu-line-color;

              .rtl & {
                right: -5px;
                left: unset;
              }
            }

            &:hover {
              color:#2d2d2d; // $sidebar-light-menu-color; //

              &:before {
                background: #fff;
              }
            }

            .rtl & {
              padding: 10px 15px 10px 0px;
            }
          }

          &:first-child {
            .nav-link {
              padding-top: 0;
            }
          }

          &:last-child {
            .nav-link {
              padding-bottom: 0;
            }
          }
        }
      }
      
      .nav-link {
        align-items: center;
        display: flex;
        padding: 15px 5px 15px 5px; //$sidebar-menu-padding; /* Commented code, for meeting the designer expectation*/
        white-space: nowrap;
        color: $sidebar-light-menu-color;
        /*Designer code start*/
        
        flex-shrink: 0;
        position: relative;
        //height: 20px;
        color: #2d2d2d;
        font-family: $sidebar-menu-font-family;
        font-size: 14px;
        font-weight: 500;
        line-height: 1;
        text-align: left;
        white-space: nowrap;
        letter-spacing: -0.1px;
        z-index: 303;
        vertical-align: middle;
       
        /*End*/
        .rtl & {
          padding: $sidebar-menu-padding-rtl;
        }

        i {
          &.menu-arrow {
            margin-left: auto;
            margin-right: 0;
            transition-duration: 0.2s;
            transition-property: transform;
            transition-timing-function: ease-in;

            &:before {
              content: "\F054";
              font-family: "Material Design Icons";
              font-size: 18px;
              line-height: 1;
              font-style: normal;
              vertical-align: middle;
              color: $sidebar-light-menu-arrow-color;
            }
          }
        }

        &[aria-expanded="true"] {

          i {
            &.menu-arrow {
              transform: rotate(90deg);
            }
          }
        }

        .menu-icon {
          display: none;
          margin-right: 1.25rem;
          width: $sidebar-icon-size;
          line-height: 1;
          font-size: 18px;
          color: $sidebar-light-menu-icon-color;

          .rtl & {
            margin-right: 0;
            margin-left: 1.25rem;
          }
        }

        .menu-title {
          //color: inherit;
          display: inline-block;
          font-size: $sidebar-menu-font-size;
          //line-height: 1;
          vertical-align: middle;
          font-weight: 500;
          height: 20px;
          color: #2d2d2d !important;
          font-family: $sidebar-menu-font-family;
          font-size: 14px;
          line-height: 20px;
          text-align: left;
          white-space: nowrap;
          letter-spacing: -0.1px;
          z-index: 303;
        }

        .badge {
          margin-left: auto;
        }

        &:hover {
          color: $sidebar-navlink-menu-item-hover-color;
        }
      }

      &.active {
        >.nav-link {
          color: $sidebar-light-menu-active-color;

          .menu-title,
          i {
            color: inherit;
          }
        }
      }

      &.nav-profile {
        max-width: 238px; //270px;
        margin-right: auto;
        margin-left: auto;
        margin-top: 30px;
        margin-bottom: 30px;

        .nav-link {
          display: flex;
          border-radius: 3px;
          border: $sidebar-nav-item-profile-border-color;
          background-color: $sidebar-nav-item-profile-bg;
          color: $sidebar-nav-item-profile-color;
          padding: 15px 25px;

          .profile-image {
            margin-right: 15px;
            position: relative;

            .rtl & {
              margin-right: 0;
              margin-left: 15px;
            }
          }

          .dot-indicator {
            position: absolute;
            bottom: 0px;
            right: 0px;
          }

          .profile-name {
            margin-bottom: 5px;
            font-weight: 500;
            font-size: 15px;
            color: $sidebar-profile-name-color;
          }

          .designation {
            margin-bottom: 0;
            font-weight: 400;
            color: $sidebar-profile-designation-color;
          }
        }
      }

      &.nav-category {
        color: $sidebar-light-category-color;
        border-bottom: 1px solid $sidebar-nav-category-border-color;
        margin: 0 30px;
        font-size: 15px;
        font-weight: 500;
        padding: 10px 0px 15px 0px;
      }
    }

    >.nav-item {
      >.nav-link {
        height: $nav-link-height;
      }
    }

    >.nav-item:not(.nav-profile) {
      >.nav-link {
        position: relative;

        &:before {
          //content: ""; /* Commented code, for meeting the designer expectation*/
          position: absolute;
          left: 30px;
          right: 50%;
          width: 10px;
          height: 10px;
          border-radius: 100%;
          border: 2px solid $sidebar-nav-item-dot-color;
          display: block;

          .rtl & {
            left: auto;
            right: 45px;
          }
        }
      }
    }

    &:not(.sub-menu) {
      >.nav-item {
        >.nav-link {
          background: transparent;
          transition-duration: 0.3s;
          transition-timing-function: "ease-in-out";
          transition-property: "background", "color", "box-shadow", "padding";
        }

        &:hover {
          &:not(.nav-profile):not(.hover-open) {
            >.nav-link:not([aria-expanded="true"]) {
              background: #f3f3f3;//#eaf5f8; // $sidebar-light-menu-hover-bg; //Commented code, for meeting the designer expectation
              //padding-left: 65px; //Commented code, for meeting the designer expectation

              .rtl & {
                padding-left: 30px;
                padding-right: 80px;
              }

              &:before {
                border-color: $sidebar-light-menu-hover-color;
              }

              .menu-title {
                color: #2d2d2d;//$sidebar-light-menu-hover-color; //Commented code, for meeting the designer expectation
              }

              .menu-arrow {
                &:before {
                  color: $sidebar-light-menu-arrow-color;
                }
              }
            }
          }
        }
      }
    }
  }
}
// RW CSS code for menu icon
.menu_icon_css{
  width: 24px;
  height: 24px;
  background-size: cover !important;
  z-index: 330;
  margin-right: 10px;
  margin-bottom: 3px;
  margin-left: 5px;
  margin-top: 5px;
}

.dashboard_icon_img{
  background: url(/static/dashboard_icon.png) no-repeat center;
}
.basic_utility_ser_icon_img{
  background: url(/static/list_icon.png) no-repeat center;
}
.custom_ser_icon_img{
  background: url(/static/custom_icon.png) no-repeat center;
}
.lead_account_icon_img{
  background: url(/static/account_icon.png) no-repeat center;
}
.categorization_ser_icon_img{
  background: url(/static/categorization_icon.png) no-repeat center;
}
.user_mgmt_icon_img{
    background: url(/static/add_user_icon.png) no-repeat center;
 }
.multi_column_lookups_icon_img{
  background: url(/static/multi_column_icon.png) no-repeat center;
}
.advance_services_icon_img{
  background: url(/static/advance_services_icon.png) no-repeat center;
}
.api_log_icon_img{
  background: url(/static/api_log_icon.png) no-repeat center;
}
.admin_icon_img{
  background: url(/static/admin_icon.png) no-repeat center;
}
.user_manual_icon_img{
  background: url(/static/user_manual_icon.png) no-repeat center;
  opacity: .5;
}
.jobs_details_icon_img{
  background: url(/static/jobs_details_icon.png) no-repeat center;
}
// RW CSS code block end

/* style for off-canvas menu*/
//RW: Commented this code

@media screen and (max-width: 991px) {
  .col-lg-2{
    width: 0%;
    padding-left: 0%;
    padding-right: 0%;
  }
  .sidebar-offcanvas {
    position: fixed;
    max-height: calc(100vh - #{$navbar-height});
    top: $navbar-height;
    bottom: 0;
    overflow: auto;
    right: -$sidebar-width-lg;
    transition: all 0.25s ease-out;
    &.active {
      right: 0;
    }
  }
  .sidebar>.nav>.nav-item>.nav-link {
    height: calc(#{$nav-link-height}-5px); //height: calc(#{$nav-link-height}-10px);
  }
}


//For -/+
@media screen and (min-width: 992px) {
  .sidebar{
    width: 22%; // 27%;
  }
  .col-lg-2 {
    flex: 0 0 10.66667% !important;
    max-width: 22% !important;
    }
    .col-lg-10{
    flex: 0 0 78% !important;
    max-width: 78% !important;
    }
  
  }

@media screen and (min-width: 1024px) {
.sidebar{
  width: 22%; // 27%;
}
.col-lg-2 {
  flex: 0 0 10.66667% !important;
  max-width: 22% !important;
  }
  .col-lg-10{
  flex: 0 0 78% !important;
  max-width: 78% !important;
  }

}
@media screen and (min-width: 1100px) {
  .sidebar{
    width: 20%; //24.6%;
  }
  .col-lg-2 {
    flex: 0 0 10.66667% !important;
    max-width: 20% !important;
  }
  .col-lg-10{
    flex: 0 0 80% !important;
    max-width: 80% !important;
  }
}
  @media screen and (min-width: 1200px) {
    .sidebar{
      width: 19%; //22.8%;
    }
    .col-lg-2 {
      flex: 0 0 10.66667% !important;
      max-width: 19% !important;
  }
  .col-lg-10{
    flex: 0 0 81% !important;
    max-width: 81% !important;
  }
  }
  
  @media screen and (min-width: 1250px) {
    .sidebar{
      width: 19%; //22%;
    }
    .col-lg-2 {
      flex: 0 0 10.66667% !important;
      max-width: 16% !important;
  }
  .col-lg-10{
    flex: 0 0 84% !important;
    max-width: 84% !important;
  }
  }
  @media screen and (min-width: 1280px) {
    .sidebar{
      width: 18%; //22%;
    }
    .col-lg-2 {
      flex: 0 0 10.66667% !important;
      max-width: 16% !important;
  }
  .col-lg-10{
    flex: 0 0 84% !important;
    max-width: 84% !important;
  }
  }
  @media screen and (min-width: 1366px) {
    .sidebar{
      width: 17%; //22%;
    }
    .col-lg-2 {
      flex: 0 0 10.66667% !important;
      max-width: 16% !important;
  }
  .col-lg-10{
    flex: 0 0 84% !important;
    max-width: 84% !important;
  }
  }
  @media screen and (min-width: 1536px){
    .sidebar{
      width: 18%;
    }
    .col-lg-2 {
      flex: 0 0 10.66667% !important;
      max-width: 16% !important;
  }
  .col-lg-10{
    flex: 0 0 84% !important;
    max-width: 84% !important;
  }
  }
  @media screen and (min-width: 1440px){
    .sidebar{
      width: 16%; // 18.65%;
    }

  .col-lg-2 {
      flex: 0 0 10.66667% !important;
      max-width: 16% !important;
  }
  .col-lg-10{
    flex: 0 0 84% !important;
    max-width: 84% !important;
  }


  }
  @media screen and (max-width: 991px){
    .sidebar{
      width: 23%; //28%; //31%; //19%;
    }
    .col-lg-2 {
      //flex: 0 0 10.66667% !important;
      //max-width: 16% !important;
      flex: 0 0 0% !important;
      max-width: 0% !important;
    }
    .col-lg-10{
      //flex: 0 0 84% !important;
      //max-width: 84% !important;
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }
    .col-10{
      padding-left: 2.5px !important;
    }

    .sidebar > .nav .nav-item .nav-link .menu-title { 
      font-size: 12px;
      //font-weight: 400; /* Commented code, for meeting the designer expectation*/
      }
      .sidebar > .nav .nav-item .sub-menu .nav-item .nav-link {
      font-size: calc(12px - 2px);
      font-weight: 400;
      }
  }
  @media screen and (max-width: 900px){
    .sidebar{
      width: 27%;
    }
 }
 @media screen and (max-width: 800px){
  .sidebar{
    width: 28%;
  }
}
  @media screen and (max-width: 768px) {

    .sidebar{
      width: 29%; //35%; //19%;
    }
    .sidebar > .nav .nav-item .nav-link .menu-title { 
      font-size: 10px;
      //font-weight: 300; /* Commented code, for meeting the designer expectation*/
      }
      .sidebar > .nav .nav-item .sub-menu .nav-item .nav-link {
      font-size: calc(12px - 2px);//calc(10px - 2px); /* Commented code, for meeting the designer expectation*/
      //font-weight: 300; /* Commented code, for meeting the designer expectation*/
      }
      .sidebar>.nav>.nav-item>.nav-link {
        height: calc(#{$nav-link-height}-10px); //height: calc(#{$nav-link-height}-15px);
      }
      .normalizer, .right-data {
        font-size: 12px !important;
        }
      .col-2 {
        flex: 0 0 20% !important;
        max-width: 80% !important;
      }
      .col-10{
        flex: 0 0 80% !important;
        max-width: 80% !important;
        padding-left: 2.5px !important;
      }
  }

  @media screen and (max-width: 600px) {
    .sidebar{
      width: 34%; //45%; //19%;
    }
    .sidebar > .nav .nav-item .nav-link .menu-title { 
      font-size: 10px;
      //font-weight: 300; /* Commented code, for meeting the designer expectation*/
      }
      .sidebar > .nav .nav-item .sub-menu .nav-item .nav-link {
      font-size: 10px; //calc(10px - 2px); /* Commented code, for meeting the designer expectation*/
      //font-weight: 300;
      }
      .normalizer, .right-data {
        font-size: 10px !important;
        }
        .col-2 {
          flex: 0 0 25% !important;
          max-width: 25% !important;
        }
        .col-10{
            padding-left: 1.5px !important;
            flex: 0 0 75% !important;
            max-width: 75% !important;
        }
        
  }
  @media screen and (max-width: 480px) {
    .sidebar{
      width: 40%; //70%; //19%;
    }
    .sidebar > .nav .nav-item .nav-link .menu-title { 
      font-size: 10px;
      //font-weight: 200; /* Commented code, for meeting the designer expectation*/
      }
      .sidebar > .nav .nav-item .sub-menu .nav-item .nav-link {
      font-size: 10px;//calc(10px - 2px); /* Commented code, for meeting the designer expectation*/
      //font-weight: 200;
      }
      .sidebar>.nav>.nav-item>.nav-link {
        height: calc(#{$nav-link-height}-15px); //height: calc(#{$nav-link-height}-20px);
      }
  }